export const contentTypes = {
  TEXT: "Text Content",
  PODCAST: "Podcast",
  READING: "Book or Article",
  MULTI: "Text and Resources",
  NO_CONTENT: "No Content",
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_APP_ID,
};

export const pageStates = {
  LOADING: "loading",
  SIGN_IN: "signIn",
  SUCCESS: "success",
  NEW_USER: "newUser",
  INVALID_TOKEN: "invalidToken",
  EMAIL_NEEDED: "emailNeeded",
  DASHBOARD: "dashboard",
  UPDATE_DISPLAYNAME: "updateDisplayName",
  COMPLETE: "complete",
  DISPLAY_OPTIONS: "displayOptions",
};

export const userDataOptions = {
  UNKNOWN: "Unknown",
  MARRIED: "Married",
  SINGLE: "Single",
  COMPLETE: "Complete",
  INCOMPLETE: "Incomplete",
  NA: "Not Applicable",
};

export const firestoreCollectionNames = {
  USERS: "users",
  COMPLETION_DATA: "completionData",
  NOTE_DATA: "noteData",
};
