import { SetStateAction } from "react";

interface iStandardInput {
  inputValue: string;
  setInputValue:
    | React.Dispatch<SetStateAction<string>>
    | ((inputValue: string) => void);
  id: string;
  inputType: string;
  placeholder: string;
  label: string;
  required?: boolean;
  passedClasses?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function StandardInput({
  inputValue,
  setInputValue,
  id,
  inputType,
  placeholder,
  label,
  required,
  passedClasses,
}: iStandardInput) {
  return (
    <label htmlFor={id}>
      <span className="prose dark:prose-invert">
        {label}
        {required && <sup className="text-primary">*</sup>}
      </span>
      <input
        type={inputType}
        id={id}
        className={`w-full p-2 mt-1 mb-2 bg-slate-50 rounded border border-slate-400 focus:outline-none focus:ring focus:ring-primary-500 ${passedClasses}`}
        placeholder={placeholder}
        max="100"
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value);
        }}
      />
    </label>
  );
}

StandardInput.defaultProps = {
  passedClasses: "",
  required: false,
};
