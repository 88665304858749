import { Link } from "react-router-dom";
import { RoundedCard } from "../UIComponents/Cards";


export default function InvalidToken() {
	return (
		<RoundedCard>
			<h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
				Something Went Wrong
			</h2>
			<hr />

			<p className="mt-4 mb-5 prose dark:prose-invert">
				We are very sorry, but something went wrong with the validation
				from the email link. Please try the process again. Thank you for
				your patience!
			</p>

			<Link
				to="/auth"
				className="prose dark:prose-invert mt-3 p-0 text-center text-sm"
			>
				Back To Sign In
			</Link>
		</RoundedCard>
	)
}