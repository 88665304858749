import { Timestamp } from "firebase/firestore";
import { userDataOptions } from "./constants";

const completeionDataObject = {
  createdDateTime: Timestamp.now(),
  s1: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
    t4: userDataOptions.INCOMPLETE,
    t5: userDataOptions.INCOMPLETE,
  },
  s2: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
    t4: userDataOptions.INCOMPLETE,
    t5: userDataOptions.INCOMPLETE,
    t6: userDataOptions.INCOMPLETE,
  },
  s3: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
    t4: userDataOptions.INCOMPLETE,
    t5: userDataOptions.INCOMPLETE,
    t6: userDataOptions.INCOMPLETE,
  },
  s4: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
  },
  s5: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
  },
  s6: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
    t4: userDataOptions.INCOMPLETE,
    t5: userDataOptions.INCOMPLETE,
    t6: userDataOptions.INCOMPLETE,
  },
  s7: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
  },
  s8: {
    t1: userDataOptions.INCOMPLETE,
    t2: userDataOptions.INCOMPLETE,
    t3: userDataOptions.INCOMPLETE,
    t4: userDataOptions.INCOMPLETE,
    t5: userDataOptions.INCOMPLETE,
  },
};

const noteDataObject = {
  createdDateTime: Timestamp.now(),
  s1: {
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
  },
  s2: {
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
    t6: "",
  },
  s3: {
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
    t6: "",
  },
  s4: {
    t1: "",
    t2: "",
    t3: "",
  },
  s5: {
    t1: "",
    t2: "",
    t3: "",
  },
  s6: {
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
    t6: "",
  },
  s7: {
    t1: "",
    t2: "",
    t3: "",
  },
  s8: {
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
  },
};

export { completeionDataObject, noteDataObject };
