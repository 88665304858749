import { useContext, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { HamburgerMenuIcon, Cross1Icon } from "@radix-ui/react-icons";
import HeaderLink from "./HeaderLink";
import HamburgerCollapsibleMenu from "./HamburgerCollapsibleMenu";
import useMediaQuery from "../../hooks/useMediaQuery";
import { DataContext } from "../DataStoreProvider";
import AuthMenuOptionsMobile from "./AuthMenuOptionsMobile";

interface iTopMenuOption {
  title: string;
  endpoint: string;
  submenu?: {
    title: string;
    endpoint: string;
  }[];
}

export default function HamburgerMenu({
  menuData,
  handleSignOut,
}: {
  menuData: iTopMenuOption[];
  handleSignOut: () => Promise<void>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallDown = useMediaQuery("(max-width: 600px)");
  const { appState } = useContext(DataContext);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <Cross1Icon className="text-primary w-7 h-7" />
        ) : (
          <HamburgerMenuIcon className="text-primary w-7 h-7" />
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={`fixed left-0 ${
            isSmallDown ? "top-[5.5rem]" : "top-[4.5rem]"
          } inset-0 z-20 bg-black/50 data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut`}
        />
        <Dialog.Content
          onEscapeKeyDown={() => setIsOpen(false)}
          onPointerDownOutside={() => setIsOpen(false)}
          onInteractOutside={() => setIsOpen(false)}
          className={`fixed  ${
            isSmallDown ? "top-[5.5rem]" : "top-[4.5rem]"
          } h-screen w-[70%] px-6 pt-6 z-20 pb-24 bg-background data-[state=open]:animate-enterFromLeft data-[state=closed]:animate-exitToLeft overflow-y-auto`}
        >
          {menuData.map(item => {
            const { title, endpoint, submenu } = item;
            if (submenu) {
              return (
                <HamburgerCollapsibleMenu
                  key={title}
                  title={title}
                  submenu={submenu}
                  onClick={() => setIsOpen(false)}
                />
              );
            }
            return (
              <Dialog.Title
                asChild
                key={title}
                onClick={() => setIsOpen(false)}
                className="block px-4 py-2"
              >
                <HeaderLink endpoint={endpoint}>{title}</HeaderLink>
              </Dialog.Title>
            );
          })}
          {process.env.REACT_APP_FLAG_AUTH === "on" && (
            <AuthMenuOptionsMobile
              isAuthenticated={appState.displayName != null}
              handleSignOut={handleSignOut}
              setIsOpen={setIsOpen}
            />
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
