import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PrimaryButton,
  OutlinedButton,
} from "../components/UIComponents/Buttons";
import { pageStates } from "../utility/constants";
import { signOutUser, DbUpdateUser } from "../utility/firebase";
import { DataContext } from "../components/DataStoreProvider";
import { ProfileSettingsSideTab } from "../components/UIComponents/SideTabs";
import ErrorPopUp from "../components/ErrorPopUp";
import { StandardInput } from "../components/UIComponents/FormFields";
import { RoundedCard } from "../components/UIComponents/Cards";

export default function Dashboard() {
  const [error, setError] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [pageState, setPageState] = useState(pageStates.UPDATE_DISPLAYNAME);

  const { appState, dispatch } = useContext(DataContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    signOutUser();
    navigate("/");
  };

  const handleUpdateDisplayName = async () => {
    await DbUpdateUser(appState.userId ?? "", "displayName", displayName)
      .then(() => {
        dispatch({
          type: "SET_DISPLAYNAME",
          payload: {
            displayName,
          },
        });
      })
      .catch(e => {
        if (e instanceof Error) setError(e.message);
      });
    navigate("/");
  };

  useEffect(() => {
    if (appState != null) {
      setDisplayName(appState.displayName ?? "");
    }
  }, [appState]);

  return (
    <main>
      {error && <ErrorPopUp error={error} setError={setError} />}
      {pageState === pageStates.DASHBOARD && (
        <section className="mx-auto container max-sm:p-20 md:py-20 w-100 md:w-1/4 flex flex-col md:flex-row">
          <div className="flex-initial">
            <ProfileSettingsSideTab />
          </div>
          <div className="md:mx-auto max-sm:w-full p-10 bg-background rounded-md shadow flex-1 flex flex-col align-items-center justify-center">
            <h2 className="text-lg md:text-3xl mb-8 font-normal prose dark:prose-invert">
              General Information
            </h2>
            <div className="mb-7">
              <div className="mb-0 font-serif font-normal prose dark:prose-invert text-base">
                Display Name
              </div>
              <div className="font-sans font-normal prose dark:prose-invert text-base mt-3">
                {appState.displayName}
                <OutlinedButton
                  className="float-right"
                  clickFunction={() => {
                    setPageState(pageStates.UPDATE_DISPLAYNAME);
                  }}
                >
                  Update
                </OutlinedButton>
              </div>
            </div>
            <Link to="/" className="md:w-96">
              <PrimaryButton className="mb-2 md:w-96">HOME</PrimaryButton>
            </Link>
            <PrimaryButton
              className="md:w-96"
              clickFunction={handleSignOut}
              disabled={false}
            >
              SIGN OUT
            </PrimaryButton>
          </div>
        </section>
      )}

      {pageState === pageStates.UPDATE_DISPLAYNAME && (
        <RoundedCard>
          <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
            Hello{" "}
            {displayName === "" || displayName == null ? "Friend" : displayName}
            .
          </h2>
          <hr className="mb-1" />

          <StandardInput
            inputValue={displayName}
            setInputValue={setDisplayName}
            id="display-name"
            inputType="text"
            label="Display Name"
            required
            placeholder="What should we call you?"
          />

          <PrimaryButton
            className="mt-4 mb-1"
            clickFunction={handleUpdateDisplayName}
            disabled={displayName.length < 1}
          >
            Update
          </PrimaryButton>
          <PrimaryButton
            className="mb-4"
            clickFunction={() => {
              navigate("/");
            }}
          >
            Cancel
          </PrimaryButton>
        </RoundedCard>
      )}
    </main>
  );
}
