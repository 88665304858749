import { RoundedCard } from "./UIComponents/Cards";

export function Loading() {
  return (
    <RoundedCard>
      <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
        Loading ...
      </h2>
    </RoundedCard>
  );
}

export function LoadingSmall() {
  return (
    <h2 className="text-2xl text-center mb-8 font-normal prose dark:prose-invert">
      Loading ...
    </h2>
  );
}
