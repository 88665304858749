import { useContext, useState, useEffect } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { KeyboardArrowDown } from "@material-ui/icons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useLocation } from "react-router-dom";
import { titleCase } from "title-case";
import { DataContext } from "./DataStoreProvider";
import { userDataOptions } from "../utility/constants";
import { iDataStoreState } from "../types/dataStoreTypes";

interface taskTitleTime {
  taskTitle?: string;
  taskTime?: number;
}
interface iStep {
  step: {
    step: number;
    dbStepId: string;
    altText: string;
    position: number;
    title: string;
    totalTasks: number;
    taskTitlesTimes: taskTitleTime[];
  };
  stepIdx: number;
}

export default function Step({ step, stepIdx }: iStep) {
  const { title, taskTitlesTimes, totalTasks } = step;
  const { appState } = useContext(DataContext);
  const [completedCount, setCompletedCount] = useState(0);

  useEffect(() => {
    if (appState?.completionData == null) {
      setCompletedCount(totalTasks);
      return;
    }

    const completionData = appState.completionData[step.dbStepId];
    let completedTasks = 0;

    if (completionData != null) {
      Object.keys(completionData).forEach(k => {
        if (completionData[k] !== userDataOptions.INCOMPLETE)
          completedTasks += 1;
      });
    }

    setCompletedCount(
      completedTasks == null ? totalTasks : Number(completedTasks)
    );
  }, [appState]);

  const displayReadTime = (taskTime: number): string => {
    if (taskTime && taskTime >= 2) {
      return `${taskTime} minutes read`;
    }
    if (taskTime && taskTime < 2 && taskTime > 0) {
      return `${taskTime} minute read`;
    }
    return "less than 1 minute read";
  };

  const displayCompleteIcon = (
    data: iDataStoreState,
    stepIndex: number,
    taskIndex: number
  ): JSX.Element => {
    const stepData =
      appState?.completionData == null
        ? null
        : appState.completionData[`s${stepIndex + 1}`];
    const taskData = stepData == null ? null : stepData[`t${taskIndex + 1}`];

    if (
      (data != null && taskData === userDataOptions.COMPLETE) ||
      taskData === userDataOptions.NA
    ) {
      return <CheckCircleIcon className="text-primary float-right" />;
    }

    return <span />;
  };

  const { pathname } = useLocation();
  const correctPath = pathname.includes("/steps") ? "" : "steps/";

  return (
    <Accordion.Item
      value={`step-${stepIdx + 1}`}
      className="flex flex-col data-[state=open]:motion-reduce:my-2 data-[state=open]:duration-75 data-[state=open]:ease-in-out"
    >
      <Accordion.Trigger className="flex justify-between items-center px-3 py-2 text-left rounded-md data-[state=open]:rounded-b-none data-[state=open]:motion-reduce:animate-expandDown shadow-sm bg-primary text-background">
        <>
          <div className="flex gap-4 place-items-center">
            <div className="relative flex place-items-center place-content-center after:content-[' '] after:absolute after:p-[14px] after:rounded-full after:bg-primary-700">
              <p className="z-[1] text-2xl font-extrabold text-primary">
                <svg className="w-10 h-10" viewBox="0 0 36 36">
                  <path
                    style={{
                      fill: "none",
                      strokeLinecap: "square",
                      strokeWidth: 4,
                    }}
                    strokeDasharray={`${
                      (completedCount * 100) / totalTasks
                    }, 100`}
                    stroke="#fff"
                    d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text
                    style={{
                      fill: "#fff",
                      fontSize: "0.8em",
                      textAnchor: "middle",
                    }}
                    x="18"
                    y="24"
                  >
                    {stepIdx + 1}
                  </text>
                </svg>
              </p>
            </div>
            <h3>{title}</h3>
          </div>
          <KeyboardArrowDown />
        </>
      </Accordion.Trigger>
      <Accordion.Content className="flex flex-col shadow-md bg-background motion-reduce:animate-slide-down">
        {taskTitlesTimes.map(({ taskTitle, taskTime }, taskIdx) => {
          const taskId = `step${stepIdx + 1}Task${taskIdx + 1}`;

          return (
            <Link
              key={taskId}
              type="button"
              className="text-left group px-4 py-2 no-underline hover:bg-primary-100 border-l-8 hover:border-l-8 border-background/0 hover:border-primary"
              to={correctPath + taskId}
            >
              <p className="text-left text-primary no-underline group-hover:underline">
                {taskTitle && titleCase(taskTitle)}
                {displayCompleteIcon(appState, stepIdx, taskIdx)}
              </p>
              <p className="font-extralight text-sm text-gray-400">
                {displayReadTime(taskTime ?? 0)}
              </p>
            </Link>
          );
        })}
      </Accordion.Content>
    </Accordion.Item>
  );
}
