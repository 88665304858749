import * as Collapsible from "@radix-ui/react-collapsible";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Link, useNavigate } from "react-router-dom";

export default function AuthMenuOptionsMobile({
  isAuthenticated,
  handleSignOut,
  setIsOpen,
}: {
  isAuthenticated: boolean;
  handleSignOut: () => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();

  const handleUpdateDisplayname = () => {
    setIsOpen(false);
    navigate("/dashboard");
  };

  return isAuthenticated ? (
    <Collapsible.Root>
      <Collapsible.Trigger className="text-primary bg-background z-[2] px-4 py-2 group flex select-none items-center justify-between gap-[2px] outline-none">
        My Account
        <CaretDownIcon className="relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180" />
      </Collapsible.Trigger>
      <Collapsible.Content className="flex flex-col overflow-hidden data-[state=open]:animate-collapsibleSlideDown data-[state=closed]:animate-collapsibleSlideUp text-primary bg-background">
        <button
          type="button"
          onClick={handleUpdateDisplayname}
          className="px-7 py-2 text-left"
        >
          Change Name
        </button>
        <button
          type="button"
          onClick={handleSignOut}
          className="px-7 py-2 text-left"
        >
          Sign Out
        </button>
      </Collapsible.Content>
    </Collapsible.Root>
  ) : (
    <Link
      to="/auth"
      onClick={() => setIsOpen(false)}
      className="hover:underline underline-offset-2 text-primary"
    >
      Sign In
    </Link>
  );
}
