import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Gather Documents",
  content: (
    <article>
      <p>
        You’re going to need to provide a number of documents during the home
        study process. You can turn these things in all at once or over the
        course of your required trainings.
      </p>

      <p>I highly recommend making a digital copy of anything you turn in.</p>

      <p>
        <a
          href="https://docs.google.com/file/d/1NUJAQgxwbq7XEkGcj3i4O3f5l3WVkjfs/edit?usp=docslist_api&filetype=msword"
          target="_blank"
          rel="noopener noreferrer"
        >
          Here’s a list of the documents
        </a>
        &nbsp;I was required to turn in.
      </p>

      <p>
        It really wow’d our licensing worker when we had everything except the
        physicals prepared and in a big pile to be turned in when she came for
        the first home visit.
      </p>
    </article>
  ),
};
