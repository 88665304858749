import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  User,
  NextOrObserver,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { firebaseConfig, firestoreCollectionNames } from "./constants";
import { buildNewUserObject } from "./functions";
import { completeionDataObject, noteDataObject } from "./dataObjects";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const DbCreateUser = async (documentId: string, displayName: string) => {
  let cdID = "";
  let ndID = "";

  try {
    await addDoc(
      collection(db, firestoreCollectionNames.COMPLETION_DATA),
      completeionDataObject
    ).then(docRef => {
      cdID = docRef.id;
    });

    await addDoc(
      collection(db, firestoreCollectionNames.NOTE_DATA),
      noteDataObject
    ).then(docRef => {
      ndID = docRef.id;
    });

    const userData = buildNewUserObject(displayName, cdID, ndID);
    await setDoc(doc(db, firestoreCollectionNames.USERS, documentId), userData);

    return userData;
  } catch (e) {
    if (e instanceof Error) throw new Error(e.message);
  }

  return null;
};

const DbGetUser = async (documentId: string) => {
  const docSnapshot = await getDoc(
    doc(db, firestoreCollectionNames.USERS, documentId)
  );
  return docSnapshot?.data();
};

const DbUpdateUser = async (
  documentId: string,
  property: string,
  newValue: string
) => {
  const userRef = doc(db, firestoreCollectionNames.USERS, documentId);
  await updateDoc(userRef, { [property]: newValue });
};

const DbUpdateCompletionData = async (
  completionDataId: string,
  step: string,
  task: string,
  newValue: string
) => {
  const completionDataRef = doc(
    db,
    firestoreCollectionNames.COMPLETION_DATA,
    completionDataId
  );
  const stepAndTask = `${step}.${task}`;
  await updateDoc(completionDataRef, {
    [stepAndTask]: newValue,
  });
};

const DbGetData = async (collectionId: string, documentId: string) => {
  const docSnapshot = await getDoc(doc(db, collectionId, documentId));
  if (!docSnapshot.exists()) throw new Error("Data not found.");
  return docSnapshot.data();
};

const setKeepSignedIn = async (keepSignedIn: boolean) => {
  if (keepSignedIn) {
    await setPersistence(auth, browserLocalPersistence);
  } else {
    await setPersistence(auth, browserSessionPersistence);
  }
};

const createNewUser = async (
  email: string,
  password: string,
  displayName: string,
  keepSignedIn: boolean
) => {
  if (auth == null) return;

  await setKeepSignedIn(keepSignedIn).then(async () => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async res => {
        await DbCreateUser(res.user.uid, displayName);
      })
      .catch(e => {
        throw new Error(e);
      });
  });
};

const sendEmailSignInLink = async (email: string) => {
  await sendSignInLinkToEmail(auth, email, {
    url: "https://localhost:3000/completesignin",
    handleCodeInApp: true,
  });
};

const completeEmailSignIn = async (email: string, keepSignedIn: boolean) => {
  await setKeepSignedIn(keepSignedIn);
  return signInWithEmailLink(auth, email, window.location.href).then(
    res => res
  );
};

const confirmGoodEmailSignInLink = () =>
  isSignInWithEmailLink(auth, window.location.href);

const setAuthObserver = (observerCallback: NextOrObserver<User>) => {
  onAuthStateChanged(auth, observerCallback);
};

const signOutUser = async () => {
  if (auth == null) return;
  await signOut(auth);
};

const googleAuthWithPopup = async () => {
  if (auth == null) return;

  const provider = new GoogleAuthProvider();

  await signInWithPopup(auth, provider)
    .then(async result => {
      const { user } = result;
      const additionalInfo = getAdditionalUserInfo(result)?.profile
        ?.name as string;

      try {
        await DbGetUser(user.uid);
      } catch (e) {
        if (
          e instanceof Error &&
          e.message.includes("not found") &&
          additionalInfo != null
        ) {
          await DbCreateUser(user.uid, additionalInfo);
        }
      }
    })
    .catch(e => {
      throw new Error(e);
    });
};

export {
  createNewUser,
  signOutUser,
  setAuthObserver,
  DbCreateUser,
  DbGetUser,
  DbUpdateUser,
  DbUpdateCompletionData,
  DbGetData,
  googleAuthWithPopup,
  sendEmailSignInLink,
  confirmGoodEmailSignInLink,
  completeEmailSignIn,
};
