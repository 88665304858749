import { Link } from "react-router-dom";
import { PrimaryButton } from "../UIComponents/Buttons";
import { RoundedCard } from "../UIComponents/Cards";

export default function SignInSuccess() {
  return (
    <RoundedCard>
      <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
        Success!
      </h2>
      <hr />

      <p className="mt-4 mb-5 prose dark:prose-invert">
        Thank you for setting up your account. You are now ready to track your
        adoption journey progress. Each time you return you can continue right
        where you left off.
      </p>
      <div className="flex justify-center">
        <Link to="/">
          <PrimaryButton className="mb-2 px-2 mr-3">Home</PrimaryButton>
        </Link>
        {/* <Link to="/dashboard">
				<PrimaryButton className="mb-2 px-2">Dashboard</PrimaryButton>
			</Link> */}
      </div>
    </RoundedCard>
  );
}
