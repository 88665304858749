import React from "react";
import { useParams } from "react-router";
import { titleCase } from "title-case";
import { ContentMediaButton } from "./UIComponents/Buttons";
import content from "../content";
import { ContentContainer } from "./UIComponents/Container";
import CompleteTaskOptions from "./UIComponents/CompleteTaskOptions";
import { contentTypes } from "../utility/constants";

export default function ContentView() {
  let { taskId } = useParams();
  if (taskId === undefined || content[taskId] === undefined) {
    taskId = contentTypes.NO_CONTENT;
  }

  const { title, type, content: body, resources } = content[taskId];

  const hasMedia = !!type.match(/Podcast|Book|Article/g);

  if (hasMedia && resources != null) {
    return (
      <ContentContainer title={title}>
        <ul className="space-y-8">
          {resources.map(({ title: resourceTitle, links }) => (
            <li key={resourceTitle} className="space-y-4">
              <h3 className="font-sans">{titleCase(resourceTitle)}</h3>
              <div className="flex flex-wrap gap-12">
                {links.map(({ link, platform }) => (
                  <React.Fragment key={`${resourceTitle}-${platform}`}>
                    <ContentMediaButton href={link} platform={platform} />
                  </React.Fragment>
                ))}
              </div>
            </li>
          ))}
        </ul>
        <CompleteTaskOptions />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer title={title}>
      {body}
      <CompleteTaskOptions />
    </ContentContainer>
  );
}
