import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

export default function AuthMenuOptions({
  isAuthenticated,
  handleSignOut,
}: {
  isAuthenticated: boolean;
  handleSignOut: () => Promise<void>;
}) {
  return isAuthenticated ? (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger className="text-primary group flex select-none items-center justify-between gap-[2px] outline-none">
        My Account
        <CaretDownIcon className="relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180" />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="absolute drop-shadow-md bg-background rounded-md data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut">
        <NavigationMenu.Link asChild>
          <Link
            to="/dashboard"
            className="hover:rounded-t-md block p-4 hover:bg-primary-100 text-primary"
          >
            Update Name
          </Link>
        </NavigationMenu.Link>

        <NavigationMenu.Link asChild>
          <button
            type="button"
            onClick={handleSignOut}
            className="hover:rounded-b-md block p-4 hover:bg-primary-100 text-primary text-left w-full"
          >
            Sign Out
          </button>
        </NavigationMenu.Link>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  ) : (
    <Link
      to="/auth"
      className="hover:underline underline-offset-2 text-primary"
    >
      Sign In
    </Link>
  );
}
