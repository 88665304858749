import React, {
  ReactNode,
  createContext,
  useMemo,
  useReducer,
  useEffect,
} from "react";
import { iDataStoreState, Action } from "../types/dataStoreTypes";
import { firestoreCollectionNames } from "../utility/constants";
import { setAuthObserver, DbGetUser, DbGetData } from "../utility/firebase";

const initialState = {
  userId: null,
  displayName: null,
  mapData: null,
};

const DataContext = createContext<{
  appState: iDataStoreState;
  dispatch: React.Dispatch<Action>;
}>({
  appState: initialState,
  dispatch: () => null,
});

const reducerMethod = (appState: iDataStoreState, action: Action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...appState,
        userId: action.payload.userId,
        displayName: action.payload.displayName,
        completionData: action.payload.completionData,
        completionDataId: action.payload.completionDataId,
      };

    case "SET_DISPLAYNAME":
      return {
        ...appState,
        displayName: action.payload.displayName,
      };

    case "SET_MAP_DATA":
      return {
        ...appState,
        mapData: action.payload.mapData,
      };

    case "SET_COMPLETION_DATA":
      return {
        ...appState,
        completionData: action.payload.completionData,
      };

    default: {
      return appState;
    }
  }
};

function DataStoreProvider({ children }: { children: ReactNode }) {
  const [appState, dispatch] = useReducer(reducerMethod, initialState);
  const stateMemo = useMemo(() => ({ appState, dispatch }), [appState]);

  useEffect(() => {
    setAuthObserver(async user => {
      if (user == null) {
        dispatch({
          type: "SET_USER",
          payload: {
            userId: null,
            displayName: null,
            completionData: null,
            completionDataId: null,
          },
        });
      } else {
        const userData = await DbGetUser(user.uid);
        const completionDataDoc =
          userData == null
            ? null
            : await DbGetData(
                firestoreCollectionNames.COMPLETION_DATA,
                userData.completionDataId
              );
        dispatch({
          type: "SET_USER",
          payload: {
            userId: user.uid,
            displayName: userData?.displayName,
            completionData:
              completionDataDoc == null
                ? null
                : {
                    s1: completionDataDoc.s1,
                    s2: completionDataDoc.s2,
                    s3: completionDataDoc.s3,
                    s4: completionDataDoc.s4,
                    s5: completionDataDoc.s5,
                    s6: completionDataDoc.s6,
                    s7: completionDataDoc.s7,
                    s8: completionDataDoc.s8,
                  },
            completionDataId:
              userData == null ? null : userData.completionDataId,
          },
        });
      }
    });
  }, []);

  return (
    <DataContext.Provider value={stateMemo}>{children}</DataContext.Provider>
  );
}

export { DataContext, DataStoreProvider };
