import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TaskIcon from "@mui/icons-material/Task";

export function ProfileSettingsSideTab() {
  return (
    <section className="md:px-10 py-5">
      <span className="uppercase text-sm font-normal prose dark:prose-invert">
        <ManageAccountsIcon
          fontSize="small"
          sx={{
            color: "grey",
          }}
        />
        Profile Settings
      </span>
    </section>
  );
}
export function TaskProgressSideTab() {
  return (
    <section>
      <span className="uppercase text-sm text-gray-800">
        <TaskIcon
          fontSize="small"
          sx={{
            color: "grey",
          }}
        />
        Task Progress
      </span>
    </section>
  );
}
