import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { DataContext } from "../components/DataStoreProvider";
import SignIn from "../components/Auth/SignIn";

export default function Auth() {
  const { appState } = useContext(DataContext);

  return (
    <main className="py-3 md:py-20">
      <section>
        {!appState.displayName ? <SignIn /> : <Navigate to="/dashboard" />}
      </section>
    </main>
  );
}
