import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import HeaderLink from "./HeaderLink";
import { signOutUser } from "../../utility/firebase";
import Logo from "./Logo";
import HeaderSubMenu from "./HeaderSubMenu";
import HamburgerMenu from "./HamburgerMenu";
import DarkModeButton from "./DarkModeButton";
import ErrorPopUp from "../ErrorPopUp";
import AuthMenuOptions from "./AuthMenuOptions";
import { DataContext } from "../DataStoreProvider";

export default function Header() {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [error, setError] = useState("");
  const { appState } = useContext(DataContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    signOutUser();
    navigate("/");
  };

  const menuData = [
    {
      title: "Steps",
      endpoint: "/steps",
    },
    {
      title: "About",
      endpoint: "",
      submenu: [
        {
          title: "About App",
          endpoint: "/about",
        },
        {
          title: "Testimonials",
          endpoint: "/testimonials",
        },
      ],
    },
    {
      title: "Get Started",
      endpoint: "",
      submenu: [
        {
          title: "Preparation",
          endpoint: "/preparation",
        },
        {
          title: "Get Your Feet Wet",
          endpoint: "/get-your-feet-wet",
        },
        {
          title: "Quick Links",
          endpoint: "/quick-links",
        },
        {
          title: "FAQ",
          endpoint: "/faq",
        },
      ],
    },
    {
      title: "While You Wait",
      endpoint: "/while-you-wait",
    },
    {
      title: "Waiting Children",
      endpoint: "/waiting-children",
    },
    {
      title: "Give",
      endpoint: "https://givebutter.com/FAO",
    },
  ];

  if (!isDesktop) {
    return (
      <header className="px-4 py-2 md:mb-4 sticky top-0 z-40 shadow-md bg-background">
        {error && <ErrorPopUp error={error} setError={setError} />}
        <div className="flex justify-between items-center">
          <HamburgerMenu menuData={menuData} handleSignOut={handleSignOut} />
          <Logo />
          <DarkModeButton />
        </div>
      </header>
    );
  }

  return (
    <header className="py-2 sticky top-0 z-40 shadow-md bg-background">
      {error && <ErrorPopUp error={error} setError={setError} />}
      <div className="container mx-auto relative flex gap-x-12 justify-center lg:justify-between items-center">
        <Logo />
        <NavigationMenu.Root>
          <NavigationMenu.List className="flex flex-wrap gap-6 items-center">
            {menuData.map(item => {
              const { title, endpoint, submenu } = item;
              if (submenu) {
                return (
                  <HeaderSubMenu key={title} title={title} submenu={submenu} />
                );
              }
              return (
                <NavigationMenu.Item key={title} asChild>
                  <HeaderLink key={title} endpoint={endpoint}>
                    {title}
                  </HeaderLink>
                </NavigationMenu.Item>
              );
            })}
            {process.env.REACT_APP_FLAG_AUTH === "on" && (
              <AuthMenuOptions
                isAuthenticated={appState.displayName != null}
                handleSignOut={handleSignOut}
              />
            )}
            <NavigationMenu.Item>
              <DarkModeButton />
            </NavigationMenu.Item>
          </NavigationMenu.List>
        </NavigationMenu.Root>
      </div>
    </header>
  );
}
