import { ReactNode } from "react";

// eslint-disable-next-line import/prefer-default-export
export function RoundedCard({ children }: { children: ReactNode }) {
  return (
    <section className="w-full md:w-96 mx-auto p-10 bg-background rounded-md shadow flex flex-col align-items-center justify-center">
      {children}
    </section>
  );
}
