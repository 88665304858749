import { useState, useEffect, useContext } from "react";
import { pageStates } from "../../utility/constants";
import {
  validateEmailFormat,
  setUserInDataStoreProvider,
} from "../../utility/functions";
import { PrimaryButton } from "../UIComponents/Buttons";
import {
  completeEmailSignIn,
  confirmGoodEmailSignInLink,
  DbCreateUser,
  DbGetUser,
  DbUpdateUser,
} from "../../utility/firebase";
import ErrorPopUp from "../ErrorPopUp";
import { StandardInput } from "../UIComponents/FormFields";
import { RoundedCard } from "../UIComponents/Cards";
import { DataContext } from "../DataStoreProvider";
import InvalidToken from "./InvalidToken";
import SignInSuccess from "./SignInSuccess";
import { Loading } from "../Loading";

export default function CompleteSignIn() {
  const [error, setError] = useState("");
  const [pageState, setPageState] = useState(pageStates.LOADING);
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [keepSignedIn, setKeepSignedIn] = useState(true);

  const { appState, dispatch } = useContext(DataContext);

  const cleanupAndSetSuccess = () => {
    localStorage.removeItem("faoEmail");
    localStorage.removeItem("faoKeepSignedIn");
    setPageState(pageStates.SUCCESS);
  };

  const handleUpdateDisplayName = async () => {
    await DbUpdateUser(appState.userId ?? "", "displayName", displayName)
      .then(() => {
        dispatch({
          type: "SET_DISPLAYNAME",
          payload: {
            displayName,
          },
        });
      })
      .catch(e => {
        if (e instanceof Error) setError(e.message);
      });
    cleanupAndSetSuccess();
  };

  const completeEmailSignInFlow = async (
    userEmail: string,
    keepSignedInValue: boolean
  ) => {
    completeEmailSignIn(userEmail, keepSignedInValue)
      .then(async res => {
        const docData = await DbGetUser(res.user.uid);

        if (docData != null) {
          setPageState(pageStates.SUCCESS);
        } else {
          await DbCreateUser(res.user.uid, "New Friend").then(
            async userData => {
              await setUserInDataStoreProvider(
                userData,
                res.user.uid,
                dispatch
              );
            }
          );

          setPageState(pageStates.NEW_USER);
        }
      })
      .catch(e => {
        if (e instanceof Error) {
          if (e.message.includes("invalid-action-code")) {
            setPageState(pageStates.INVALID_TOKEN);
          } else {
            setError(e.message);
          }
        }
      });
  };

  const handleEmailInput = (inputValue: string) => {
    setIsValidEmail(validateEmailFormat(inputValue));
    setEmail(inputValue);
  };

  useEffect(() => {
    if (confirmGoodEmailSignInLink()) {
      try {
        const emailFromStorage = localStorage.getItem("faoEmail");

        if (emailFromStorage == null) {
          setPageState(pageStates.EMAIL_NEEDED);
          return;
        }

        setKeepSignedIn(localStorage.getItem("faoKeepSignedIn") === "true");
        completeEmailSignInFlow(emailFromStorage, keepSignedIn);
      } catch (e) {
        if (e instanceof Error) setError(e.message);
      }
    } else {
      setPageState(pageStates.INVALID_TOKEN);
    }

    cleanupAndSetSuccess();
  }, []);

  return (
    <main className="py-3 md:py-20">
      <section>
        {error && <ErrorPopUp error={error} setError={setError} />}
        {pageState === pageStates.LOADING && <Loading />}

        {pageState === pageStates.INVALID_TOKEN && <InvalidToken />}

        {pageState === pageStates.NEW_USER && (
          <RoundedCard>
            <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
              We are so excited to join you on your adoption journey! What is
              your first name?
            </h2>
            <hr />

            <StandardInput
              inputValue={displayName}
              setInputValue={setDisplayName}
              id="display-name"
              inputType="text"
              label="Display Name"
              required
              placeholder="What should we call you?"
            />

            <PrimaryButton
              className="my-4"
              clickFunction={handleUpdateDisplayName}
              disabled={displayName.length < 1}
            >
              Submit
            </PrimaryButton>
          </RoundedCard>
        )}

        {pageState === pageStates.EMAIL_NEEDED && (
          <RoundedCard>
            <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
              Confirm Email Address
            </h2>

            <hr className="mb-3" />

            <p className="mt-4 mb-5 prose dark:prose-invert">
              If you would be so kind as to confirm your email address and we
              can complete the sign in process.
            </p>

            <StandardInput
              inputValue={email}
              setInputValue={handleEmailInput}
              id="email-address"
              inputType="text"
              label="Email Address"
              required
              placeholder="me@place.web"
              passedClasses={isValidEmail ? "" : "outline outline-pink-500"}
            />

            {!isValidEmail && (
              <span className="text-sm text-pink-500 mb-1">
                Please provide a valid email (me@place.web)
              </span>
            )}

            <div className="mb-3 flex flex-row justify-between">
              <label htmlFor="keep-signed-in" className="flex flex-row">
                <input
                  type="checkbox"
                  id="keep-signed-in"
                  className="mr-1 accent-primary-500 text-white"
                  checked={keepSignedIn}
                  onChange={() => {
                    setKeepSignedIn(!keepSignedIn);
                  }}
                />
                <span className="text-sm font-thin prose dark:prose-invert">
                  Keep Me Signed In
                </span>
              </label>
            </div>

            <PrimaryButton
              className="my-4"
              clickFunction={() => {
                completeEmailSignInFlow(email, keepSignedIn);
              }}
              disabled={!isValidEmail}
            >
              Submit
            </PrimaryButton>
          </RoundedCard>
        )}

        {pageState === pageStates.SUCCESS && <SignInSuccess />}

        {pageState === pageStates.EMAIL_NEEDED ||
          (pageState === pageStates.NEW_USER && (
            <section>
              <hr />
              <span className="prose dark:prose-invert text-primary mt-4 p-0 text-right text-xs">
                <sup>*</sup>Required
              </span>
            </section>
          ))}
      </section>
    </main>
  );
}
