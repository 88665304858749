import { Timestamp } from "firebase/firestore";
import { firestoreCollectionNames, userDataOptions } from "./constants";
import { Action } from "../types/dataStoreTypes";
import { DbGetData } from "./firebase";

const validateEmailFormat = (stringToCheck: string) =>
  String(stringToCheck)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) != null;

export interface iNewUserObject {
  createdDateTime: Timestamp;
  displayName: string;
  completionDataId: string;
  notesDataId: string;
  state: string;
  relationshipStatus: string;
}

const buildNewUserObject = (
  displayName: string,
  completionDataId: string,
  notesDataId: string
) => ({
  createdDateTime: Timestamp.now(),
  displayName,
  completionDataId,
  notesDataId,
  state: userDataOptions.UNKNOWN,
  relationshipStatus: userDataOptions.UNKNOWN,
});

const setUserInDataStoreProvider = async (
  userData: iNewUserObject | null,
  userId: string,
  dispatch: React.Dispatch<Action>
) => {
  const completionDataDoc =
    userData == null
      ? null
      : await DbGetData(
          firestoreCollectionNames.COMPLETION_DATA,
          userData.completionDataId
        );

  dispatch({
    type: "SET_USER",
    payload: {
      userId,
      displayName: userData?.displayName ?? null,
      completionData:
        completionDataDoc == null
          ? null
          : {
              s1: completionDataDoc.s1,
              s2: completionDataDoc.s2,
              s3: completionDataDoc.s3,
              s4: completionDataDoc.s4,
              s5: completionDataDoc.s5,
              s6: completionDataDoc.s6,
              s7: completionDataDoc.s7,
              s8: completionDataDoc.s8,
            },
      completionDataId: userData == null ? null : userData.completionDataId,
    },
  });
};

export { validateEmailFormat, buildNewUserObject, setUserInDataStoreProvider };
