import { useEffect, useState, useContext } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router";
import { PrimaryButton } from "./Buttons";
import { DbUpdateCompletionData } from "../../utility/firebase";
import ErrorPopUp from "../ErrorPopUp";
import { DataContext } from "../DataStoreProvider";
import { userDataOptions, pageStates } from "../../utility/constants";
import { LoadingSmall } from "../Loading";

export default function CompleteTaskOptions() {
  const [error, setError] = useState("");
  const [step, setStep] = useState("");
  const [task, setTask] = useState("");
  const [componentState, setComponentState] = useState(pageStates.LOADING);
  const [completionState, setCompletionState] = useState(
    userDataOptions.INCOMPLETE
  );
  const { appState, dispatch } = useContext(DataContext);

  const { taskId } = useParams();

  useEffect(() => {
    if (taskId != null) {
      setStep(
        `s${taskId.substring(taskId.indexOf("p") + 1, taskId.indexOf("p") + 2)}`
      );
      setTask(`t${taskId.substring(taskId.indexOf("k") + 1)}`);

      const { completionData } = appState;

      if (completionData != null) {
        const stepData = completionData[step];

        if (stepData == null) return;
        setCompletionState(stepData[task] ?? userDataOptions.INCOMPLETE);

        setComponentState(
          stepData[task] === userDataOptions.INCOMPLETE
            ? pageStates.DISPLAY_OPTIONS
            : pageStates.COMPLETE
        );
      }
    }
  });

  const handleSetTaskCompletionState = async (newValue: string) => {
    if (appState.completionDataId == null || appState.completionData == null) {
      return;
    }

    setComponentState(pageStates.LOADING);

    await DbUpdateCompletionData(
      appState.completionDataId,
      step,
      task,
      newValue
    ).catch(e => {
      if (e instanceof Error) setError(e.message);
    });

    const { completionData } = appState;
    const stepData = completionData[step];
    if (stepData != null) stepData[task] = newValue;

    dispatch({
      type: "SET_COMPLETION_DATA",
      payload: { completionData },
    });

    setCompletionState(newValue);

    setComponentState(
      newValue === userDataOptions.INCOMPLETE
        ? pageStates.DISPLAY_OPTIONS
        : pageStates.COMPLETE
    );
  };

  return step == null || appState.userId == null ? null : (
    <section className="mt-16 mb-10">
      {error && <ErrorPopUp error={error} setError={setError} />}

      {componentState === pageStates.LOADING && <LoadingSmall />}

      {componentState === pageStates.DISPLAY_OPTIONS && (
        <div>
          <h3 className="text-center">Ready to mark this one off the list?</h3>
          <div className="w-100 flex justify-center">
            <PrimaryButton
              className="w-36 mr-3"
              clickFunction={() => {
                handleSetTaskCompletionState(userDataOptions.COMPLETE);
              }}
            >
              Complete
            </PrimaryButton>
            <PrimaryButton
              className="w-36"
              clickFunction={() => {
                handleSetTaskCompletionState(userDataOptions.NA);
              }}
            >
              Not Applicable
            </PrimaryButton>
          </div>
        </div>
      )}

      {componentState === pageStates.COMPLETE && (
        <div className="w-100 text-center">
          <div className="flex justify-center items-center">
            <span className="text-primary">
              <CheckCircleOutlineIcon />
            </span>
            <span className="ml-2">Task Marked: {completionState}</span>
          </div>

          <button
            className="prose dark:prose-invert mt-1 p-0 text-center text-sm pointer underline"
            type="button"
            onClick={() => {
              handleSetTaskCompletionState(userDataOptions.INCOMPLETE);
            }}
          >
            Uncheck This One
          </button>
        </div>
      )}
    </section>
  );
}
