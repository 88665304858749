import { useState } from "react";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link } from "react-router-dom";
import { pageStates } from "../../utility/constants";
import { validateEmailFormat } from "../../utility/functions";
import { PrimaryButton } from "../UIComponents/Buttons";
import { sendEmailSignInLink } from "../../utility/firebase";
import ErrorPopUp from "../ErrorPopUp";
import { StandardInput } from "../UIComponents/FormFields";
import { RoundedCard } from "../UIComponents/Cards";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(true);
  const [error, setError] = useState("");
  const [pageState, setPageState] = useState(pageStates.SIGN_IN);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSendSignInEmail = async () => {
    localStorage.setItem("faoEmail", email);
    localStorage.setItem("faoKeepSignedIn", keepSignedIn.toString());
    await sendEmailSignInLink(email)
      .then(() => setPageState(pageStates.SUCCESS))
      .catch(e => {
        if (e instanceof Error) setError(e.message);
      });
  };

  const handleEmailInput = (inputValue: string) => {
    setIsValidEmail(validateEmailFormat(inputValue));
    setEmail(inputValue);
  };

  return (
    <RoundedCard>
      {error && <ErrorPopUp error={error} setError={setError} />}
      <h2 className="text-4xl text-center mb-8 font-normal prose dark:prose-invert">
        {pageState === pageStates.SIGN_IN ? "Sign In" : "Email Sent"}
      </h2>

      {pageState === pageStates.SIGN_IN && (
        <>
          <StandardInput
            inputValue={email}
            setInputValue={handleEmailInput}
            id="email"
            inputType="text"
            label="Email Address"
            required
            placeholder="me@place.web"
            passedClasses={
              isValidEmail ? "mb-3" : "outline outline-pink-500 mb-3"
            }
          />

          {!isValidEmail && (
            <span className="text-sm text-pink-500 mb-1">
              Please provide a valid email (me@place.web)
            </span>
          )}

          <div className="mt-3">
            <div className="mb-1">
              <label htmlFor="terms-and-conditions" className="flex flex-row">
                <input
                  type="checkbox"
                  id="terms-and-conditions"
                  className="mr-1 accent-primary-500 text-white"
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                />
                <span className="pl-1 text-sm font-thin prose dark:prose-invert">
                  I agree to the Privacy Policy and Terms of Service as
                  described in the links below{" "}
                  <sup className="text-primary">*</sup>
                </span>
              </label>
            </div>

            <div className="mb-6 flex flex-row justify-around">
              <a
                href="https://app.termageddon.com/api/policy/YW5vM2RGQklOVXR2UzFJM2QyYzlQUT09?h-align=left&table-style=accordion"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-primary text-sm underline"
              >
                Privacy Policy
                <sup className="ml-1">
                  <NorthEastIcon sx={{ fontSize: 12 }} />
                </sup>
              </a>
              <a
                href="https://app.termageddon.com/api/policy/UVZabFZXRjNUMmRvV25vMFJXYzlQUT09?h-align=left&table-style=accordion"
                target="_blank"
                rel="noopener noreferrer"
                className="pr-2 text-primary text-sm underline"
              >
                Terms of Service
                <sup className="ml-1">
                  <NorthEastIcon sx={{ fontSize: 12 }} />
                </sup>
              </a>
            </div>
          </div>

          <div className="mb-3 flex flex-row justify-between">
            <label htmlFor="keep-signed-in" className="flex flex-row">
              <input
                type="checkbox"
                id="keep-signed-in"
                className="mr-1 accent-primary-500 text-white"
                checked={keepSignedIn}
                onChange={() => {
                  setKeepSignedIn(!keepSignedIn);
                }}
              />
              <span className="text-sm font-thin prose dark:prose-invert">
                Keep Me Signed In
              </span>
            </label>
          </div>

          <PrimaryButton
            className="mb-6"
            clickFunction={handleSendSignInEmail}
            disabled={!isValidEmail || email.length === 0 || !acceptTerms}
          >
            SIGN IN
          </PrimaryButton>
        </>
      )}

      {pageState === pageStates.SUCCESS && (
        <>
          <hr />
          <p className="mt-4 mb-5 prose dark:prose-invert">
            An email has been sent with instructions on how to finish signing
            in.
          </p>

          <p className="mt-4 mb-5 prose dark:prose-invert">
            Be sure to check your junk/spam folder and add
            &quot;noreply@forgotten-adoption-option-app.firebaseapp.com&quot; to
            your contact list.
          </p>
        </>
      )}

      <hr />

      {pageState === pageStates.SIGN_IN && (
        <span className="prose dark:prose-invert text-primary mt-4 p-0 text-right text-xs">
          <sup>*</sup>Required
        </span>
      )}

      <Link
        to="/"
        className="prose dark:prose-invert mt-3 p-0 text-center text-sm"
      >
        Return To Homepage
      </Link>
    </RoundedCard>
  );
}
